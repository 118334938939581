/* Estilos para a parte do formulário de login */
.login-form {
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: #888888; /* Defina uma cor de texto que contraste bem com o fundo */
  background-color: #ffffff; /* Defina uma cor de fundo que contraste bem com o texto */
}

/* Estilos para os campos de entrada */
.login-form input {
  color: #888888;
  font-size: 24px;
  margin: 10px 0; 
}


.login-container {
  display: flex;
  height: 100vh;
}

.login-left {
  flex: 2; /* 80% da largura total */
  background: url(../../imgs/bg-login.png) no-repeat center;
  background-size: cover;
  background-position: center;
}

.login-right {
  flex: 1; /* 20% da largura total */
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  padding: 20px;
  color: #888888; /* Defina uma cor de texto que contraste bem com o fundo */
}
