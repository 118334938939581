@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900');

html {
  background-color: #fcfcfc;
}

body {
  margin: 0;
  font-family: 'Manrope', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
